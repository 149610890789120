export const query = `query getFirstProductWithHasDiscount($externalId: String!, $limit: Int, $filters: ProductFilters, $sort: ProductSort, $withPriceRange: Boolean = false, $withCheckoutCurrencies: Boolean = false) {
  appSettings(externalId: $externalId) {
    widgetSettings
  }
  siteSettingsCurrencies  @include(if: $withCheckoutCurrencies) {
    checkoutCurrencies
  }
  catalog {
    products(limit: $limit, filters: $filters, sort: $sort, onlyVisible: true) {
      list {
        id
        description
        isVisible
        sku
        ribbon
        brand
        price
        comparePrice
        discountedPrice
        formattedPrice
        formattedComparePrice
        formattedDiscountedPrice
        pricePerUnit
        formattedPricePerUnit
        pricePerUnitData {
          baseQuantity
          baseMeasurementUnit
        }
        seoTitle
        seoDescription
        createVersion
        digitalProductFileItems {
          fileId
          fileType
          fileName
        }
        itemDiscount {
          discountRuleName
          priceAfterDiscount
        }
        productItems {
          id
          price
          comparePrice
          formattedPrice
          formattedComparePrice
          hasDiscount
          pricePerUnit
          formattedPricePerUnit
          optionsSelections
          isVisible
          availableForPreOrder
          isTrackingInventory
          inventory {
            status
            quantity
          }
          preOrderInfo {
            limit
            message
          }
          sku
          weight
          surcharge
          subscriptionPlans {
          list {
            id
            price
            formattedPrice
            pricePerUnit
            formattedPricePerUnit
          }
        }
        }
        name
        isTrackingInventory
        inventory {
          status
          quantity
          availableForPreOrder
          preOrderInfoView {
            message
            preOrder
            limit
          }
        }
        isVisible
        isManageProductItems
        productItemsPreOrderAvailability
        isInStock
        media {
          id
          url
          fullUrl
          altText
          thumbnailFullUrl: fullUrl(width: 50, height: 50)
          mediaType
          videoType
          videoFiles {
            url
            width
            height
            format
            quality
          }
          width
          height
          index
          title
        }
        customTextFields {
          key
          title
          isMandatory
          inputLimit
        }
        nextOptionsSelectionId
        options {
          id
          title
          optionType
          key
          selections {
            id
            value
            description
            key
            linkedMediaItems {
              altText
              url
              fullUrl
              thumbnailFullUrl: fullUrl(width: 50, height: 50)
              mediaType
              width
              height
              index
              title
              videoFiles {
                url
                width
                height
                format
                quality
              }
            }
          }
        }
        productType
        urlPart
        additionalInfo {
          id
          title
          description
          index
        }
        subscriptionPlans {
          list(onlyVisible: true) {
            id
            name
            tagline
            frequency
            interval
            duration
            price
            formattedPrice
            pricePerUnit
            formattedPricePerUnit
          }
          oneTimePurchase {
            index
          }
        }
        priceRange(withSubscriptionPriceRange: true) @include(if: $withPriceRange) {
          fromPriceFormatted
        }
        discount {
          mode
          value
        }
        currency
        weight
        seoJson
      }
    }
  }
}`;
